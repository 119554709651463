<template>
  <div v-if="$router.currentRoute.path != '/login' && $router.currentRoute.path != '/signup'">

    <a class="close-navbar-toggler collapsed" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
</a>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark mb-5">
      <div class="navbar-brand" href="#">
        <img src="../../assets/logo_header_kendrick_presents.png" class="navbar-brand mb-0" style="max-height: 60px;" />
      </div>
       <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
<div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav">
        <li class="nav-item mr-2 mb-2">
          <router-link class="nav-link" to="/invitation">{{$t('AdminDashboard.headings.invitation')}}</router-link>
        </li>
        <li class="nav-item mr-2 mb-2">
          <router-link class="nav-link" to="/address">{{$t('AdminDashboard.headings.information')}}</router-link>
        </li>
        <li class="nav-item mr-2 mb-2" v-if="false">
          <router-link class="nav-link" to="/sleeping">sleeping</router-link>
        </li>
        <li class="nav-item mr-2 mb-2">
          <router-link class="nav-link" to="/tournament">{{$t('AdminDashboard.headings.beerPongHouseRules')}}</router-link>
        </li>
        <li class="nav-item mr-2 mb-2">
          <router-link class="nav-link" to="/fotoUpload">{{$t('AdminDashboard.headings.fotoUpload')}}</router-link>
        </li>
        <li class="nav-item mr-2 mb-2" v-if="isAdmin">
          <router-link class="nav-link" to="/admin">Adminbereich</router-link>
        </li>
        <li class="nav-item mr-2 mb-2">
          <div class="btn-group btn-group-toggle" data-toggle="buttons">
            <label class="btn btn-light active">
              <input type="radio" @click="lang('de')" name="options" id="option1" checked><country-flag size="small" country='de' />
            </label>
            <label class="btn btn-light">
              <input type="radio" @click="lang('en')" name="options" id="option2"><country-flag size="small" country='gb' />
            </label>
          </div>
        </li>
        <li class="nav-item mr-2 mb-2">
          <button class="btn btn-secondary" @click="logout">{{username}} abmelden</button>
        </li>
      </ul>
      </div>
    </nav>

  </div>
<div v-else>
</div>
</template>

<script>
import Jwt from '@/services/Jwt.vue';
import 'bootstrap/dist/js/bootstrap.min.js';
import CountryFlag from 'vue-country-flag';

export default {
  components: {
    CountryFlag
  },
  data() {
    return {
      isAdmin: false,
      username: '',
      locale: this.$root.$i18n.locale,
    }
  },
  methods: {
    logout() {
      localStorage.removeItem("kendrick");
      window.location.reload();
    },
    lang(selection) {
      this.$root.$i18n.locale = selection;
      this.locale = selection;
      return selection;
    }
  },
  mounted() {
      let jwt = new Jwt();
      try {
      this.username = jwt.get_value("name");
      this.isAdmin = jwt.get_value("admin");
      } catch(e) {
        this.username = "";
        this.isAdmin = false;
      }
  },
}

</script>
<style>
  .navbar{
/*   z-index should be above .close-navbar-toggler */
  z-index:2;
}
.close-navbar-toggler{
  position:absolute;
  top:0;
  left:0;
  height:100%;
  width:100%;
  z-index:1;
  cursor:pointer;
}
.close-navbar-toggler.collapsed{
  z-index:-1;
}
</style>