class Config {

    system_mode = 'deploy'
    api_base_url
    auth_header_key

    constructor() {
      if(this.system_mode === 'local') {
        const url = new URL(window.location.origin + '/kendrick_backend/');
        url.port = '';

        this.api_base_url = url.toString();
        this.auth_header_key = 'authToken';
       }
       else if (this.system_mode === 'deploy') {
        this.api_base_url = window.location.origin + '/kendrickpresents_backend2022/';
        this.auth_header_key = 'Authtoken';
       }
    }

    getApiBaseUrl() {
        return this.api_base_url;
    }

    getAuthHeaderKey() {
        return this.auth_header_key;
    }

}

export default new Config();