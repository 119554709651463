<template>
  <div class="custom-fullscreen">
    <div class="custom-bg">

      <div style="height: 100dvh; overflow: scroll; position:absolute; bottom: 0;">

        <!-- home made kinda parallax -->
        <div style="height: 80vh; width:100vw"></div>

        <div class="card bg-dark border-light p-3 custom-card">
          <div class="card-header">
            <h2>{{ $t('portalBase.login') }}</h2>
            <div class="pull-right">
              <button @click="$root.$i18n.locale = 'en'" class="btn"><country-flag country='gb' /></button>
              <button @click="$root.$i18n.locale = 'de'" class="btn"><country-flag country='de' /></button>
            </div>
          </div>
          <form @submit="loginBtn">
            <div v-if="message" class="alert alert-danger">
              {{message}}
              </div>
              <div class="form-group text-left">
                <input type="text" v-model="username" :placeholder="$t('portalBase.username')" @change="message = ''" class="form-control" style="background-color: white; opacity: 1;"/>
              </div>
              <div class="form-group text-left">
                <input type="password" v-model="password" :placeholder="$t('portalBase.password')"  class="form-control"/>
              </div>
              <div class="form-group">
                <input type="submit" class="btn btn-primary btn-block" :value="$t('portalBase.signIn')"/>
                <small v-if="false"><router-link to="/reset-password">Passwort vergessen</router-link></small>
              </div>
            </form>
          </div>

        <div style="height: 50vh; width: 100vw;"></div>

      </div>
      
    </div>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService.ts';
import CountryFlag from 'vue-country-flag';

export default {
  name: 'LoginPanel',
  components: {
    CountryFlag
  },
  props: {
    
  },
  data() {
    return {
      username: "",
      password: "",
      message: "",
      lets_go: 1,
      deviceOrientation: "portrait",
    }
  },
  mounted() {
    window.addEventListener(
      "orientationchange",
      this.handleOrientationChange
    );
    
    if (window.screen.orientation.type === 'landscape-primary') {
      this.deviceOrientation = "landscape";
    }
    else {
      this.deviceOrientation = "portrait";
    }
  },
  methods: {
    handleVidEnd() {
      let letsGo = document.getElementById('letsGoBtn');
      letsGo.scrollIntoView({ behavior: 'smooth'});
    },
    handleOrientationChange() {
      const orientation = window.screen.orientation.type
      if (orientation === "portrait-primary") {
        this.deviceOrientation = "portrait";
      } else if (orientation === "landscape-primary") {
        this.deviceOrientation = "landscape";
      }
    },
    async loginBtn(e) {
      e.preventDefault();
      let user = {
        username: this.username,
        password: this.password
      }
      try {
        let response = await AuthService.login(user);
        if(response.headers.authtoken) {
          this.message = "";
          localStorage.setItem("kendrick", response.headers.authtoken);
          window.location.reload('');
        }
        else {
          this.message = "versuchs nochmal";
        }
      } catch(e) {
        this.message = "versuchs nochmal";
      }
    }
  }
}
</script>

<style scoped>

.custom-fullscreen {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  overflow: hidden;
}
  
.custom-bg {
  height: 100%;
  background-size:contain;
  background-repeat: no-repeat;
  background-position: top center;
}
.custom-card {
  opacity: 0.85;
  color: white;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: -15vh;
  border: none;
  border-radius: 25px;
}

@media(orientation: landscape) {
  .custom-card {
    max-width: 45%;
  }
  .custom-bg {
    background-image: url('../../img/TitelbildQuerformat.png');
  }
}

@media(orientation: portrait) {
  .custom-bg {
    background-image: url('../../img/TitelbildHochformat.png');
  }
}

</style>