import axios from "axios";
import authHeader from "@/helper/AuthHeader.ts";
import Config from './ConfigServerice.ts';

class AuthService {

    constructor() {
        console.log(Config.getApiBaseUrl());
    }

    sayHi() {
        console.log("Hi from AuthService");
        console.log(authHeader());

            axios.get( Config.getApiBaseUrl() + "index.php", {
                params: {
                    method: 'get_invitation_data',
                },
                headers: {
                    [Config.getAuthHeaderKey()]: localStorage.getItem("kendrick"),
                }
            }).then((response) => {
                console.log(response.data);
            });
        

    }

    async authorize() {
        console.log("in authorize()");
        const token = localStorage.getItem("kendrick");

        let response;
        
        try {
            response = await axios.get( Config.getApiBaseUrl() + 'index.php', {
                params: {
                    method: 'authorize'
                },
                headers: {
                    [Config.getAuthHeaderKey()]: token
                } 
            });
        } 
        catch(error) {
            localStorage.removeItem("kendrick");
            window.location.reload();
            return false;
        }

        if(response.headers.authtoken) {
            localStorage.setItem("kendrick", response.headers.authtoken);
            return true;
        }
        localStorage.removeItem("kendrick");
        return false;

    }

    async login(user) {
        return axios
        .get( Config.getApiBaseUrl() + 'index.php', {
            params: {
            method: 'login',
            username: user.username,
            password: user.password
            }
        });
    }

    logout() {

    }

    register() {

    }
}

export default new AuthService();