<template>
    <div class="col">
        <input
          v-on:focus="$event.target.select()" 
          ref="clone" 
          readonly 
          :value="cpValue" class="row" />
        <button class="btn bg-light" @click="cpToClipboard">{{buttonText}}</button>
    </div>
</template>
<script>
export default {
    name: 'CopyButton',
    props: {
        cpValue: String,
        buttonText: String,
    },
    comonents: {

    },
    methods: {
        cpToClipboard() {
            this.$refs.clone.focus();

            try {
              document.execCommand('copy');
            } catch (err) {
              alert('Oops, unable to copy');
            }

            /* unselect the range */
            window.getSelection().removeAllRanges()
        }


    }
}
</script>
<style scoped>
</style>
