<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-5">
              <div class="row card bg-dark border-dark mb-2">
                <button class="card-body btn btn-warning" @click="play">Play the Song</button>
                <button class="card-footer btn btn-secondary" @click="pause">||</button>
                <audio autoplay ref="audio" id="audio" src="../../../img/see_you_again.mp3" ></audio>
              </div>
            <div class="row mb-2">
              <InvitationForm></InvitationForm>
            </div>
            </div>
              <div class="col-md-7">
                <div class="card bg-dark" style="font-size: 18px; color: #aaa">
                  <p class="card-header border-light h1">{{$t('AdminDashboard.headings.invitation')}}</p>
                  <p class="card-body text-justify" v-html="$t('Content.Invitation.main')"></p>
                </div>
              </div>
        </div>
     </div>

</template>

<script>

import AuthService from "@/services/AuthService.ts";
import InvitationForm from "./InvitationForm"

export default ({
    name: 'InvitationMain',
    components: {
        InvitationForm
    },
    data() {
        return {
            mytext: "Hier den Einladungstext in der jeweiligen Sprache und mit heredoc laden",
            audio: null,
        }
    },
    async mounted() {
        await AuthService.authorize();
        //this.audio = new Audio(require('../../assets/song.ogg'));
        //if(!this.$root.audioPlaying) {
          //  this.audio.play();
            //this.$root.audioPlaying = true;
        //}
    },
    methods: {
        play(event) {
          document.getElementById('audio').play();
        },
        pause(event) {
          document.getElementById('audio').pause();
        }
    }
})
</script>

<style scoped>

#invitation-container {
    background-color: rgba(0, 0, 0, 0.2);
    max-width: 90vw;
    margin: auto;
    padding: 15px;
}
</style>