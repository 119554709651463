<template>
<div class="container">
    <ul class="nav nav-tabs mb-2">
        <li class="nav-item mr-1">
            <a class="nav-link active" name="invitation" @click="selectTab">Einladungen</a>
        </li>
        <li class="nav-item mr-1">
            <a class="nav-link active" name="fotos" @click="selectTab">Fotos</a>
        </li>
    </ul>
    <div class="row-2">

        <div class="dashboard">

            <div v-if="selectedTab == 'invitation'" class="col-lg-6">
                <div class="card mb-2">
                    <div class="card-header">{{$t('AdminDashboard.headings.invite')}}</div>
                        <div class="card-body">
                        <InvitePanel @newInvitation="newInvite()"></InvitePanel>
                    </div>
                </div>
            </div>

            <div v-if="selectedTab == 'invitation'" class="col-lg-6">
                <div class="card mb-2">
                    <div class="card-header">{{$t('AdminDashboard.headings.answers')}}</div>
                    <InvitationOverview></InvitationOverview>
                </div>
            </div>

            <div v-if="selectedTab == 'invitation'" class="col-lg-6">
                <div class="card mb-2">
                    <div class="card-header">{{$t('AdminDashboard.headings.invited')}}</div>
                        <div class="card-body">
                        <InvitedOverview :key="reloadInviteTable"></InvitedOverview>
                    </div>
                </div>
            </div>

            <div v-if="selectedTab == 'invitation'" class="col-lg-6">
                <div class="card mb-2">
                    <div class="card-header">{{$t('AdminDashboard.headings.registriert')}}</div>
                    <UserOverview></UserOverview>
                </div>
            </div>

            <div v-if="selectedTab == 'fotos'" class="col-lg-12">
                <div class="card mb-2">
                    <div class="card-header">{{$t('AdminDashboard.headings.fotoUpload')}}</div>
                    <FotoUploadOverview class="card-body"></FotoUploadOverview>
                </div>
            </div>

        </div>
    </div>
</div>
</template>


<script>
import UserOverview from './UserOverview.vue';
import InvitePanel from './InvitePanel.vue';
import InvitedOverview from './InvitedOverview.vue';
import InvitationOverview from './InvitationOverview.vue';
import AuthService from '@/services/AuthService.ts';
import FotoUploadOverview from './FotoUploadOverview.vue';

export default {
    name: 'AdminDashboard',
    props: [],
    components: {
    UserOverview,
    InvitePanel,
    InvitedOverview,
    InvitationOverview,
    FotoUploadOverview
},
    data() {
        return {
            titleUserOverview: String,
            reloadInviteTable: 0,
            selectedTab: 'invitation',
            inviteTitle: this.$t('AdminDashboard.headings.invited')
        }
    },
    methods: {
        newInvite() {
            this.reloadInviteTable += 1;
        },
        selectTab() {
            this.selectedTab = event.target.name;
        }
    },
    async mounted() {
        await AuthService.authorize();
        this.reloadInviteTable = 0;
    }
}
</script>

<style scoped>

.dashboard {
    display: flex;
    flex-flow: row wrap;
}
.admin-widget {
    margin: auto;
    margin-top: 5px;
    padding: 10px;
    max-width: 600px;
    min-width: 350px;
    min-height: 100px;
    border-radius: 3px;
}
</style>
