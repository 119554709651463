<template>
<div>
    <div class="invite-table">
        <b-table striped hover :items="serverReturn" :fields="fields">
            <template #cell(token)="data">
                <copy-button :cp-value="data.value" button-text="copy invite link"></copy-button>
            </template>
            <template #cell(id)="data">
                <button v-if="!prepareDelete" @click="prepareDelete = data.value" class="btn btn-danger">x</button>
                <div v-if="prepareDelete == data.value">
                    <button @click="remove(data.value)" class="btn btn-danger">delete row</button>
                    <button @click="prepareDelete = 0" class="btn btn-info">cancel</button>
                </div>
            </template>
        </b-table>
    </div>
</div>
</template>

<script>
import axios from 'axios';

import Config from '@/services/ConfigServerice.ts';
import CopyButton from '../../customElements/CopyButton.vue'

export default {
    name: 'InvitedOverview',
    components: {
        CopyButton,
    },
    data() {
        return {
            fields: [{key: 'username', sortable: true}, {key: 'token', onclick: "copyToken"}, {key: 'id'}, {key: 'id', sortable: true}, 'email', 'handy'],
            serverReturn: Array,
            prepareDelete: 0,
        }
    },
    methods: {
        
        getInviteData() {
            axios.get( Config.getApiBaseUrl() + "index.php", {
                 params: {
                     method: 'get_invite_data',
                 },
                 headers: {
                     [Config.getAuthHeaderKey()]: localStorage.getItem("kendrick"),
                 }
             }).then((response) => {
                 this.serverReturn = response.data;
                 this.serverReturn.forEach(element => {
                     element.token = window.location.origin + "/signup?token=" + element.token;
                 });
            });
        },
        async remove(id) {
            console.log("want to remove " + id);

            const response = await axios.delete( Config.getApiBaseUrl() + "index.php", {
                params: {
                    deleteId: id
                },
                headers: {
                    [Config.getAuthHeaderKey()]: localStorage.getItem("kendrick"),
                    Method: 'delete_invited_user'
                }
            });

            this.getInviteData();
            this.prepareDelete = 0;
        }
    },
     mounted() {
         this.getInviteData();
         console.log("test");
    },
    }

</script>

<style scoped>

.invite-table {
    overflow: scroll;
}

</style>