<template>
<div>
    <div class="invite-table">
        <b-table striped hover :items="serverReturn" :fields="fields"></b-table>
    </div>
</div>
</template>

<script>
import axios from 'axios';

import Config from '@/services/ConfigServerice.ts';

export default {
    name: 'InvitationOverview',
    comonents: {

    },
    data() {
        return {
            fields: [ 'username', {key: 'dabei', sortable: true}, 'hilfe', 'turnier', 'partner', 'wishes', 'no_partner'],
            serverReturn: Array,
        }
    },
    methods: {
        getInviteData() {
                axios.get( Config.getApiBaseUrl() + "index.php", {
                    params: {
                        method: 'get_invitation_data',
                    },
                    headers: {
                        [Config.getAuthHeaderKey()]: localStorage.getItem("kendrick"),
                    }
                }).then((response) => {
                    this.serverReturn = response.data;
                });
            }
        },
        mounted() {
            this.getInviteData();
        }
}

</script>

<style scoped>

.invite-table {
    overflow: scroll;
}

</style>