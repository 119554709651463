<template>
    <div>
        <!--@TODO -->
        <!-- remove this page to not download 100gigs accidently-->
        <div class="row">
            <div v-for="image in images" class="col-md-2 col-sm-3 col-4 col-lg-1 mb-5">
                <img :src="image" style="max-width: 100%" />
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

import Config from '@/services/ConfigServerice.ts';

export default {
    name: 'FotoUploadFromUser',
    props: ['fotouser'],
    comonents: {
        
    },
    data() {
        return {
            username: "",
            email: "",
            token: localStorage.getItem("kendrick"),
            errMessage: '',
            testImg: null,
            images: [],
            downloadState: '',
            numberOfFotos: 0,
            downloadLink: '#',
        }
    },
    methods: {

        async get_picture(imgN) {
            try {
            const response = await axios.get(Config.getApiBaseUrl(), {
                responseType: 'arraybuffer',
                     params: {
                        method: 'get_foto_from_user',
                        imgName: imgN,
                        fotouser: this.fotouser,
                    },
                    headers: {
                        [Config.getAuthHeaderKey()]: localStorage.getItem("kendrick"),
                    }
                });


                console.log(response.data);
                if(!response.headers['Content-Type'] == 'image/*') return;
                let blob = new Blob(
                       [response.data],
                       { type: 'image/*' }
                        );
                        console.log(blob);

                this.images.push(URL.createObjectURL(blob));
                console.log(this.images);


            } catch(e) {
                console.log(e);
            }
         
        },

    },
    async mounted() {
        console.log("fuo mounted");
        console.log(this.fotouser);
        this.images = new Array();

        try {
            const response = await axios.get(Config.getApiBaseUrl(), {
                     params: {
                        method: 'get_fotos_from_user',
                        fotouser: this.fotouser,
                    },
                    headers: {
                        [Config.getAuthHeaderKey()]: localStorage.getItem("kendrick"),
                    }
                });
            console.log(response.data);
            const list = response.data.filter(
                (e) => { return (e !== '.' && e !== '..'); }
            );
            list.forEach(e => {
               this.get_picture(e); 
            });
            

            
        } catch(e) {
            this.errorMessage = e;
        }

    }
}

</script>

<style scoped>
</style>