<template>
    <div>
        <div>
            <b-table striped hover :items="serverReturn">
            <template #cell(id)="data">
                <button v-if="!prepareDelete" @click="prepareDelete = data.value" class="btn btn-danger">x</button>
                <div v-if="prepareDelete == data.value">
                    <button @click="remove(data.value)" class="btn btn-danger">delete row</button>
                    <button @click="prepareDelete = 0" class="btn btn-info">cancel</button>
                </div>
            </template>
            </b-table>
        </div>
</div>
</template>


<script>
import axios from 'axios';

import Config from '@/services/ConfigServerice.ts';

export default {
    name: 'UserOverview',
    components: {

    },
    data() {
        return {
            serverReturn: Array,
            prepareDelete: 0,
        }
    },
    methods: {
        getUserData() {
            axios.get( Config.getApiBaseUrl() + "index.php", {
                params: {
                    method: 'get_user_data',
                    token: localStorage.getItem("kendrick"),
                },
                headers: {
                        [Config.getAuthHeaderKey()]: localStorage.getItem("kendrick"),
                    }
            }).then((response) => {
                this.serverReturn = response.data;
            });
        },
        async remove(id) {
            const response = await axios.delete( Config.getApiBaseUrl() + "index.php", {
                params: {
                    deleteId: id
                },
                headers: {
                    [Config.getAuthHeaderKey()]: localStorage.getItem("kendrick"),
                    method: 'delete_registered_user'
                }
            });

            this.getUserData();
            this.prepareDelete = 0;

        }
    },
    mounted() {
        this.getUserData();
    }
}
</script>
