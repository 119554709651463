<script>
import Vue from "vue";
import { Buffer } from "buffer";



class Jwt extends Vue{

    constructor() {
        super();
    }

    get_value(key) {
        let token = localStorage.getItem("kendrick");
        
        let payload = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
        return payload[key];
    }

}

export default Jwt;

</script>