<template>
    <div>
        <!--@TODO -->
        <!-- remove this page to not download 100gigs accidently-->
        <div class="row">
            <div v-for="image in images" class="col-md-3 col-sm-6 col-lg-2 mb-5">
                <img :src="image" style="max-width: 100%" />
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

import Config from '@/services/ConfigServerice.ts';

export default {
    name: 'FotoUploadUserOverview',
    comonents: {

    },
    data() {
        return {
            username: "",
            email: "",
            token: localStorage.getItem("kendrick"),
            errMessage: '',
            testImg: null,
            images: [],
            downloadState: '',
            numberOfFotos: 0,
            downloadLink: '#',
        }
    },
    methods: {

        async get_picture(imgN) {
            try {
            const response = await axios.get(Config.getApiBaseUrl(), {
                responseType: 'arraybuffer',
                     params: {
                        method: 'get_foto',
                        imgName: imgN,
                    },
                    headers: {
                        [Config.getAuthHeaderKey()]: localStorage.getItem("kendrick"),
                    }
                });


                console.log(response.data);
                if(!response.headers['Content-Type'] == 'image/*') return;
                let blob = new Blob(
                       [response.data],
                       { type: 'image/*' }
                        );
                        console.log(blob);

                this.images.push(URL.createObjectURL(blob));
                console.log(this.images);


            } catch(e) {
                console.log(e);
            }
         
        },
        async downloadFotos() {
            this.downloadState = 'requesting';

            try {
                const response = await axios.get(Config.getApiBaseUrl(), {
                        responseType: 'arraybuffer',
                        params: {
                            method: 'get_download_fotos',
                        },
                        headers: {
                            [Config.getAuthHeaderKey()]: localStorage.getItem("kendrick"),
                        }
                    },
                );

                console.log(response.data);
                let blob = new Blob(
                       [response.data],
                       { type: 'application/zip' }
                        );
                        console.log(blob);

                const obj_url = URL.createObjectURL(blob);
                this.downloadLink = obj_url;
                console.log(obj_url);

                //naja, nicht so richtig ne
                this.downloadState = 'success';
            } catch (e) {
                this.downloadState = 'error';
                console.log(e);
            }
        }

    },
    async mounted() {
        console.log("fuo mounted")
        this.images = new Array();

        try {
            const response = await axios.get(Config.getApiBaseUrl(), {
                     params: {
                        method: 'get_foto_upload_list',
                    },
                    headers: {
                        [Config.getAuthHeaderKey()]: localStorage.getItem("kendrick"),
                    }
                });
            console.log(response.data);
            response.data.forEach(e => {
               this.get_picture(e); 
            });
            

            
        } catch(e) {
            this.errorMessage = e;
        }

    }
}

</script>

<style scoped>
</style>