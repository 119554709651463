<template>
<div>

    <div class="mb-5 row">
        <div class="col-md-6 offset-md-3">
            <button v-if="downloadState != 'success'" @click="downloadFotos" class="btn btn-info d-block w-100">Download All</button>
            <div class="alert alert-info" v-if="downloadState == 'requesting'">Downloading files...</div>
            <div class="alert alert-warning" v-if="downloadState == 'success'"><a download="kendrick_fotos.zip" :href='downloadLink' @click="downloadState = ''">Fotos SPEICHERN</a></div>
            <div class="alert alert-danger" v-if="downloadState == 'error'">Error</div>
        </div>
    </div>

    
    <div v-if="!fotousersLoading" v-for="fotouser in fotousers">
        <div class="card bg-light mb-2">
            <p class="h2 card-header">{{fotouser}}</p>
            <FotoUploadFromUser :fotouser="fotouser" class="card-body"></FotoUploadFromUser>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios';

import Config from '@/services/ConfigServerice.ts';
import FotoUploadFromUser from './FotoUploadFromUser.vue';

export default {
    name: "FotoUploadOverview",
    components: {
        FotoUploadFromUser,
    },
    data() {
        return {
            username: "",
            email: "",
            token: localStorage.getItem("kendrick"),
            errMessage: "",
            testImg: null,
            images: [],
            downloadState: "",
            downloadLink: "#",
            fotousers: [],
            fotousersLoading: true,
        };
    },
    methods: {
        async get_picture(imgN, fotouser) {
            console.log("get " + imgN + "for " + fotouser);
            try {
                const response = await axios.get(Config.getApiBaseUrl(), {
                    responseType: "arraybuffer",
                    params: {
                        method: "get_foto",
                        imgName: imgN,
                    },
                    headers: {
                        [Config.getAuthHeaderKey()]: localStorage.getItem("kendrick"),
                    }
                });
                console.log(response.data);
                if (!response.headers["Content-Type"] == "image/*")
                    return;
                let blob = new Blob([response.data], { type: "image/*" });
                console.log(blob);
                this.images[fotouser].data.push(URL.createObjectURL(blob));
                console.log(fotouser);
                console.log(this.images);
            }
            catch (e) {
                console.log(e);
            }
        },
        async downloadFotos() {
            this.downloadState = "requesting";
            try {
                const response = await axios.get(Config.getApiBaseUrl(), {
                    responseType: "arraybuffer",
                    params: {
                        method: "get_download_fotos",
                    },
                    headers: {
                        [Config.getAuthHeaderKey()]: localStorage.getItem("kendrick"),
                    }
                });
                console.log(response.data);
                let blob = new Blob([response.data], { type: "application/zip" });
                console.log(blob);
                const obj_url = URL.createObjectURL(blob);
                this.downloadLink = obj_url;
                console.log(obj_url);
                //naja, nicht so richtig ne
                this.downloadState = "success";
            }
            catch (e) {
                this.downloadState = "error";
                console.log(e);
            }
        },
        async get_user_pictures(user) {
            try {
                const response = await axios.get(Config.getApiBaseUrl(), {
                    params: {
                        method: "get_fotos_from_user",
                        fotouser: user,
                    },
                    headers: {
                        [Config.getAuthHeaderKey()]: localStorage.getItem("kendrick"),
                    }
                });
                console.log(response.data);
                response.data.forEach(e => {
                    this.get_picture(e, user);
                });
            }
            catch (e) {
                this.errorMessage = e;
            }
        }
    },
    async mounted() {
        this.fotousersLoading = true;
        console.log("fuo mounted");
        this.images = new Array();
        try {
            const response = await axios.get(Config.getApiBaseUrl(), {
                params: {
                    method: "get_foto_list",
                },
                headers: {
                    [Config.getAuthHeaderKey()]: localStorage.getItem("kendrick"),
                }
            });
            console.log("fotousers");
            
            this.fotousers = response.data.filter(
                (e) => { return (e !== '.' && e !== '..'); }
            );
            console.log(this.fotousers);

            /*response.data.forEach(e => {
                this.images[e] = { name: e, data: [] };
                this.get_user_pictures(e);
            });*/
        }
        catch (e) {
            this.errorMessage = e;
        }
        console.log("images");
        console.log(this.images);

        this.fotousersLoading = false;
    },
}

</script>

<style scoped>
</style>