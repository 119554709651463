<template>
<div>
    <div id="form-panel">
      <div class="alert alert-danger" v-if="errMessage">{{errMessage}}</div>
      <form @change="errMessage = ''">
        <div class="login-form-field">
          <label>Benutzername</label>
          <b-form-input type="text" v-model="username" placeholder="Max"></b-form-input>
        </div>
        <div class="login-form-field">
          <label>E-Mail</label>
          <b-form-input type="email" v-model="email" placeholder="max@muster.de"></b-form-input>
        </div>
        <div class="login-form-field ">
          <b-button  block v-on:click="invite()" :disabled="!username">Einladen</b-button><br />
        </div>
      </form>
      </div>
</div>
</template>

<script>
import axios from 'axios';

import Config from '@/services/ConfigServerice.ts';

export default {
    name: 'InvitePanel',
    comonents: {

    },
    data() {
        return {
            username: "",
            email: "",
            token: localStorage.getItem("kendrick"),
            errMessage: '',
        }
    },
    methods: {
        async invite() {
            try {
            const response = await axios
            .get(Config.getApiBaseUrl() + 'index.php', {
                params: {
                    method: 'invite',
                    username: this.username,
                    email: this.email,
                    token: this.token,
                },
                headers: {
                    [Config.getAuthHeaderKey()]: localStorage.getItem("kendrick"),
                }
            });

            if(response.status !== 200) {
                this.errMessage = 'user existiert bereits';
            }
            else {
                this.errMessage = "";
                this.$emit('newInvitation');
            }
        } catch (e) {
                this.errMessage = 'user existiert bereits';
        }
        },
    }
}

</script>

<style scoped>
</style>