import { BIconKanbanFill } from "bootstrap-vue";

export default {
  de: {
    portalBase: {
      login: "Anmeldung",
      username: 'Benutzername',
      password: 'Passwort',
      choosePassword: 'Passwort wählen',
      confirmPassword: 'Passwort bestätigen',
      signIn: 'Anmelden',
      yes: 'Ja',
      no: 'Nein',
      save: 'speichern',
    },
    InvitationForm: {
        registration: 'Anmeldung',
        showUpLabel: 'Am 02.10. bin ich am Start:',
        naKlar: 'na klar',
        leiderNicht: 'leider nicht',
        showUpAdvise: '(bitte sagt so schnell es geht zu oder ab)',
        beerpongLabel: 'Ich will am Beerpong-Turnier teilnehmen:',
        partnerAndTeamLabel: 'Wunschpartner und Teamname',
        noPartnerCheck: 'zufälliger Partner',
        beerpongAdvise: '(Team bitte bis zum 30.09. anmelden)',
        helpLabel: 'Ich helfe beim Abbau',
        specialWishes: 'Besondere Wünsche / Anmerkungen:',
    },
    AdminDashboard: {
      headings: {
        invite: 'Einladen',
        invited: 'Eingeladen',
        invitation: 'Einladung',
        information: 'Informationen',
        tournament: 'Turnier',
        beerPongHouseRules: 'Beerpong Hausregeln',
        fotoUpload: 'Fotoupload',
        registriert: 'Registrierte Anmeldungen',
        answers: 'Erhaltene Antworten'
      }
    },
    Content: {
        Invitation: {
            main: `Liebe Freunde,<br /><br /> 
              zum allerletzten Mal öffnen sich die Pforten Isernhagens für eine unvergessliche Nacht. Es ist nicht 
              nur mein Geburtstag – es ist das Finale. Mit dem Auszug meiner Eltern schließen sich die Türen dieser 
              Location für immer. Einen besseren Grund, um noch einmal mit euch das Leben zu feiern, gibt es
              wohl kaum!<br /><br /> 
              Wie immer feiern wir draußen im Garten. Der wesentliche Teil ist mit Festzelten überdacht und
              beheizt, ‘ne warme Jacke mitzunehmen lohnt sich trotzdem. Auch für die innere Wärme ist gesorgt
              die Bars sind voll ausgestattet und für jeden Geschmack ist mehr als genug vorhanden. Solltet ihr
              spezielle Getränkewünsche haben, schreibt es ins Anmeldefeld, und ich sorge dafür, dass niemand
              durstig bleibt. Ihr braucht also keine Getränke mitzubringen. Es gibt ein paar Snacks, aber wer hungrig 
              kommt, tanzt nur halb so wild. Kommt also nicht mit leerem Magen!<br /><br />
              Für nächtliche Geschäfte steht euch der gute alte Gartenzaun (und ‘ne Schubkarre) zur Verfügung –
              für die Damen und besonderen Momente steht wie immer ein romantisches ToiToi bereit. Das Haus
              bleibt während der Party geschlossen.<br /><br />  
              Dieses Jahr verzichten wir auf das Bierpongturnier, für Flunkyball, Ragecage, Opus und so weiter ist
              natürlich trotzdem gesorgt.<br /><br />
              Solltet ihr euren Partner oder Freunde mitbringen wollen, gebt mir bitte rechtzeitig Bescheid. Zum  
              etwas weniger glamourösen Teil - dem Abbau: Niemand hat nach der durchzechten Nacht Bock
              darauf, aber ich würde mich sehr über jede Hilfe freuen.<br /><br />
              Weitere Informationen wie Adresse, Uhrzeit, Anreise und die Hausregeln für Bierpong findet ihr in
              den entsprechenden Reitern auf der Party-Website.<br /><br />
              Ein besonderer Dank geht an Kai, der mir wieder bei der Gestaltung der Website geholfen hat!
              Ich erwarte euch in bester Laune, trinkfest und mit pulsierenden Tanzbeinen und freue mich über 
              jede Zusage.<br /><br />
              Euer Hendrik`,
            
        },
        Information: {
            main: `Datum: 02.10.2024, ab 19 Uhr<br /><br />
             Ort: Burgwedeler Straße 71, 30916 Isernhagen<br /><br />
             Anfahrt: Metronom in Richtung Uelzen ab Hannover HBf (meistens um 20 vor der vollen Stunde),
             Ausstieg nach zwei Stationen „Isernhagen“, ab dort 25 Minuten zu Fuß oder per RufTaxi unter der
             Telefonnummer 05031 175500 Spätestens 2 Stunden vorher dort anrufen, das gilt mit dem ganz
             normalen Zugticket. Die Station heißt dann „Vor der Hahle“. Ansonsten gibt es auch noch andere
             Busverbindungen bis „An der Beeke“, von dort sind es nur 5 Minuten zu Fuß.
             Nahegelegene Straßenbahnstationen sind „Fasanenkrug“ oder „Altwarmbüchen“.<br /><br /> 
             Schlafplätze sind nach Absprache genügend vorhanden!<br />
             `,
        },
        Tournament: {
            rules: `Gespielt wird mit 6 Bechern und 2 Bällen.\
            <br /><br />\
            Beginner: Der Beginner wird durch eine Runde Schere-Stein-Papier entschieden, wobei zuerst mit Ehrenstein gestartet werden muss.\
            <br /><br />\
            Das Beginner Team beginnt zunächst mit nur einem Ball.\
            <br /><br />\
            Abstand: Beim Wurf darf der Ellenbogen die Tischkante nicht überschreiten!\
            <br /><br />\
             Balls-back: Werden in einer Runde zwei Becher getroffen, so gibt es nur einen Ball zurück, treffen beide Bälle in den gleichen Becher gibt es Balls-back für beide Spieler, es zählen trotzdem nur 2 Becher.\
            <br /><br />\
             Airball: Es wird ohne Airball gespielt. Fliegt der Ball über den Tisch, ohne etwas zu berühren geht das Spiel ganz normal weiter.\
            <br /><br />\
             Bouncer: Prallt der Ball vom Tisch ab darf er weggeschlagen werden. Sollte er dennoch den Becher des Gegnerteams treffen, so kann sich das treffende Team einen zweiten Becher aussuchen, der getrunken werden muss.\
            <br /><br />\
             Trickshot: sofern der Ball den Boden noch nicht berührt hat und in die eigene Tischhälfte zurückrollt, erlangt das Team einen Trickshot. Würfe mit links (bei Rechtshändern) zählen nicht dazu, es sei denn du outest dich laut und deutlich als Pussy.\
            <br /><br />\
             Umstellen: Jedes Team darf einmal während des Spiels die Becher umstellen. Sie dürfen dies nicht, wenn sie a) inmitten der Runde (z.B. durch balls-back) umstellen wollen, oder b) das Gegnerteam in der vorhergehenden Runde bereits die eigenen Becher umgestellt hat. Außerdem: Nach dem Umstellen müssen alle Becher zusammenhängend sein und der hinterste Becher muss die Hinterlinie berühren.\
            <br /><br />\
             Ausgleichen am Ende: Hat ein Team gewonnen, so hat das Gegnerteam die Möglichkeit mit einem Treffer in einen der Becher immer wieder auszugleichen. Dabei spielt es keine Rolle, welches Team begonnen hat.\
            <br /><br />\
             Vorzeitiges Ende: Ist Team A an der Reihe, obwohl Team B den zu trinkenden Becher noch nicht ausgetrunken hat, kann Team A das Spiel sofort gewinnen, indem sie den zu trinkenden Becher von Team B treffen.\
            <br /><br />\
             Blasen: Beide Teams dürfen einen rotierenden Ball aus dem Becher herauspusten, hat er das Bier noch nicht berührt wird der Becher als nicht getroffen gewertet. Dies gilt auch für die Herren der Schöpfung.\
            <br /><br />\
             Viel Erfolg!`,
        },
        FotoUpload: {
            label: 'Foto upload',
            infoText: 'In diesem internen Bereich können später Fotos und Videos hochgeladen werden. Ich würde mich sehr freuen, wenn ihr eure Medien von der Party hochladen könnt, dann kann ich mir die später noch einmal anschauen!<br /><br />Mit eurem Erscheinen auf der Party stimmt ihr automatisch zu, dass dies für euch okay ist.',
            selectFotos: 'Fotos auswählen',
            uploadNow: 'Jetzt hochladen',
            yourUploads: 'Deine Uploads',
        }

    },
    
  },
  en: {
    portalBase: {
      login: "Login",
      username: 'Username',
      password: 'Password',
      choosePassword: 'choose password',
      confirmPassword: 'confirm password',
      signIn: 'Sign in',
      yes: 'yes',
      no: 'no',
      save: 'save',
    },
    InvitationForm: {
        registration: 'Registration',
        showUpLabel: 'I will show up',
        naKlar: 'yes',
        leiderNicht: 'no',
        showUpAdvise: '(please let me know as soon as possible)',
        beerpongLabel: 'I want to participate in the beer pong tournament',
        partnerAndTeamLabel: 'preferred partner and team name',
        noPartnerCheck: 'random partner',
        beerpongAdvise: '(Please register your team by September 30th at the latest)',
        helpLabel: 'will you help me clean up?',
        specialWishes: 'Special requests / comments',
    },
    AdminDashboard: {
      headings: {
        invite: 'Invite',
        invited: 'Invited',
        invitation: 'Invitation',
        information: 'Information',
        tournament: 'Tournament',
        beerPongHouseRules: 'Beer Pong rules',
        fotoUpload: 'Media upload',
        registriert: 'Registered Users',
        answers: 'Answers Received'
      }
    },
    Content: {
        Invitation: {
            main: `Dear friends,<br /><br />
              For the very last time, the gates of Isernhagen will open for an unforgettable night. With my parents
              moving out, the doors to this location will close forever. What better reason to celebrate life with all 
              of you one last time?<br /><br />
              As always, the party will be held outside in the garden. The main area will be covered with tents and
              be heated, but it's still a good idea to bring a warm jacket. And don't worry, the bars will be fully 
              stocked, and there will be plenty of drinks for every taste to keep you warm from the inside. If you
              have any special drink requests, please add them to the registration form, and I’ll make sure no one
              ends up thirsty. There's no need to bring your own drinks. We'll have a few snacks, but if you arrive 
              hungry, you won’t dance as wild. So, please don’t come on an empty stomach!<br /><br />
              The trusty garden fence (and a wheelbarrow) will serve as a restroom for the gents – and for the 
              ladies and special moments, as always, there will be a romantic ToiToi. The house will remain closed
              during the party.<br /><br />
              This year, we’re skipping the beer pong tournament, but don't worry – Flunkyball, Ragecage, Opus,
              and other games will still be in full swing.<br /><br /> 
              If you'd like to bring your partner or friends, please let me know in advance. And now for the less
              glamorous part – the cleanup: Nobody is ever in the mood to help after a wild night, but I’d really
              appreciate any help the next day.<br /><br />
              You can find more information, such as the address, start time, travel directions, and house rules for
              beer pong, in the corresponding sections on the party website.<br /><br />
              A special thanks goes to Kai, who once again helped me with the website design!<br /><br />
              I expect you all to show up in the best spirits, ready to drink and dance the night away. I can't wait to
              see your RSVPs!<br /><br />
              Cheers,<br />\  
              Hendrik<br />\  
              `
        },
        Information: {
            main: `Date: October 2nd, 2024, from 7 p.m (you can come earlier, this is just the official party start)<br /><br />
              Location: Burgwedeler Straße 71, 30916 Isernhagen You can all stay at my place again. Bring a tent
              and sleeping bag if possible. There are the following options for your arrival from abroad:<br /><br /> 
              Plane: Hanover Airport HAJ is only a few minutes away by car. I would be happy to pick you up from there. 
              Other airports are Hamburg HAM or Berlin BER, from there you can take a train.
              Train: In contrast to other countries, it is important in Germany to book trains early and not just a few days in advance, 
              otherwise the price rises. The train station is called Hannover Hauptbahnhof (main station). From
              there you can come to my house very easily and cheaply. A train called "Metronom" departures to
              Isernhagen (in direction of either Uelzen or Hamburg) every 20 minutes on the hour (until midnight 
              and then again from 6 a.m.). It’s only 2 stations and the train ticket costs € 3.90. The journey takes 12
              minutes. I'll be happy to pick you up from Isernhagen. But please let me know in good time so I can
              plan everything.<br /><br />
              Flixbus: station name is Hannover ZOB. Very close to the main train station (1 minute by foot).<br /><br />
              Car: We will find parking for your car easily, but please let me know in advance.<br /><br />
              In general, you are welcome (depending on the travel costs) to come a few days earlier and also stay
              a little longer. There might also be the possibility to visit Hamburg or Berlin together in the following
              days after cleanup and to be on the road together in Germany for a few more days, depending on
              your connections. Of course, we should plan something like this in advance. So before you book a
              flight or train, let me know about your arrival and departure times.
              `
        },
        Tournament: {
            rules: 'The game is played with 6 cups and 2 balls.<br /><br />Beginner: The beginner is decided by a round of rock-paper-scissors, starting with a stone first (weird German thing).<br /><br />The beginner team starts with just one ball.<br /><br />Distance: When throwing, the elbow must not exceed the edge of the table!<br /><br />Balls-back: If two cups are hit in a round, only one ball is returned, if both balls hit the same cup, there are balls-back for both players, but only 2 cups count.<br /><br />Airball: It is played without airball. If the ball flies over the table without touching anything, the game continues as normal.<br /><br />Bouncer: If the ball bounces on the table, it can be knocked away. Should it still hit the opposing team\'s cup, the team that hit it can choose a second cup that must be drunk.<br /><br />Trickshot: if the ball hasn\'t touched the ground and rolls back into its own half of the table, the team gets a trickshot. Left-handed throws (if right-handed) don\'t count unless you clearly expose yourself as a pussy.<br /><br />Reposition: Each team may reposition cups once during the game. You are not allowed to do this if a) you want to switch in the middle of the round (e.g. by balls-back), or b) the opposing team has already repositioned their own cups in the previous round. Also: all cups must be connected and the last cup must touch the back line.<br /><br />Equalizing at the end: If one team has won, the opposing team can always equalize by hitting one of the cups. It doesn\'t matter which team started.<br /><br />Premature End: If it is Team A\'s turn before Team B has finished drinking, Team A can win the game immediately by hitting Team B\'s drinking cup.<br /><br />Blow: Both teams may blow a rotating ball out of the cup, if it hasn\'t touched the beer the cup is scored as not hit. This also applies to the guys. I wish you best success and good luck!',
        },
FotoUpload: {
            label: 'Media upload',
            infoText: 'Later, photos and videos can be uploaded to this internal area. I would be very happy if you could do so shortly after the party or in between so that I can keep some memories.<br /><br />By appearing at the party you automatically agree that you are okay with this.',
            selectFotos: 'Select Fotos',
            uploadNow: 'Upload Now',
            yourUploads: 'Your Uploads',
        }
}
  }
}
