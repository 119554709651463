<template>
    <h1>Übernachtung</h1>
</template>

<script>
import AuthService from "@/services/AuthService.ts"


export default ({
    name: 'SleepingMain',
    components: {

    },
    async mounted() {
        AuthService.authorize();
    }
})
</script>

<style scoped>

</style>