<template>
        <div class="card  bg-secondary text-white border-secondary">
            <div class="h2 card-header bg-dark">
                {{$t('InvitationForm.registration')}}
            </div>
            <div class="card-body">
    <form @submit="invitationBtn" @change="message = ''">
        <div class="col-md-auto">
        <div class="form-group text-left">
            <div><label class="h4">{{$t('InvitationForm.showUpLabel')}}</label></div>
            <label class="btn btn-secondary border-white form-control text-left">
                <input type="radio" class="form-check-inline" inline v-model="dabei" name="dabei-radio" value="1" />{{$t('InvitationForm.naKlar')}}
            </label>
            <label class="btn btn-secondary border-white form-control text-left">
                <input type="radio" class="form-check-inline" inline v-model="dabei" name="dabei-radio" value="0" />
                {{$t('InvitationForm.leiderNicht')}}
            </label>
            <small>
                {{$t('InvitationForm.showUpAdvise')}}
            </small>
        </div>
        <div class="form-group text-left" v-if="dabei == 1" label="Nimmst du am Turnier teil?">
            <!--
            <div><label class="h4">
                {{$t('InvitationForm.beerpongLabel')}}
                </label></div>
            <label class="btn btn-secondary border-white form-control text-left">
                <input type="radio" class="form-check-inline" v-model="turnier" name="turnier-radio" value="1" />
                {{$t('portalBase.yes')}}
            </label>
                <label class="btn btn-secondary border-white form-control text-left">
            <input type="radio" class="form-check-inline" v-model="turnier" name="turnier-radio" value="0">
                {{$t('portalBase.no')}}
            </label>
            <div class="form-group" v-if="turnier == 1">
                <label for="wunschpartner-input" class="h5">
                    {{$t('InvitationForm.partnerAndTeamLabel')}}</label>
                <label class="btn btn-secondary border-white form-control text-left">
                    <input type="checkbox" class="form-check-inline" value="0" v-model="noPartner" />{{$t('InvitationForm.noPartnerCheck')}}
                </label>
                <input type="text" class="form-control" :disabled="noPartner" v-model="partner" id="wunschpartner-input" />
                <small>{{$t('InvitationForm.beerpongAdvise')}}</small>
            </div>
            -->
        <div class="form-group text-left">
            <div><label class="h4">
                {{$t('InvitationForm.helpLabel')}}
            </label></div>
            <label class="btn btn-secondary border-white form-control text-left">
            <input type="radio" class="form-check-inline" v-model="hilfe" name="hilfe-radio" value="1">
        {{$t('portalBase.yes')}}
        </label>
            <label class="btn btn-secondary border-white form-control text-left">
            <input type="radio" class="form-check-inline" v-model="hilfe" name="hilfe-radio" value="0">{{$t('portalBase.no')}}</label>
        </div>
<div class="form-group text-left">
                <label for="specialWishes-input" class="h5">{{$t('InvitationForm.specialWishes')}}</label>
                <input type="text" class="form-control" v-model="wishes" id="specialWishes-input" />

        </div>
                </div>
        <div v-if="message" class="alert alert-info">
            {{message}}
        </div>

        <input type="submit" class="btn btn-success form-control" :value="$t('portalBase.save')" />
        </div>
    </form>
</div>
</div>
</template>

<script>
import axios from "axios";


import Config from '@/services/ConfigServerice.ts';


export default ({

    name: "InvitationForm",
    components: {

    },
    data() {
        return {
            dabei: 0,
            turnier: 0,
            hilfe: 0,
            partner: '',
            noPartner: false,
            wishes: '',
            message: "loading...",
        }
    },
    async mounted() {
        try {
                const response = await axios.get(Config.getApiBaseUrl(), {
                     params: {
                        method: 'get_invitation_form_data',
                    },
                    headers: {
                        [Config.getAuthHeaderKey()]: localStorage.getItem("kendrick"),
                    }
                });
                this.dabei = response.data.dabei;
                this.turnier = response.data.turnier;
                this.hilfe = response.data.hilfe;
                this.partner = response.data.partner;
                this.wishes = response.data.wishes;
                this.noPartner = (response.data.no_partner) ? true : false;
                this.message = "";
            } catch (e) {
                this.dabei = 0;
                this.turnier = 0;
                this.hilfe = 0;
                this.partner = "";
                this.message = "";
            }
    },
    methods: {
        invitationBtn(e) {
            e.preventDefault();
            axios.get( Config.getApiBaseUrl() , {
                params: {
                    method: 'invitation_form',
                    dabei: this.dabei,
                    turnier: this.turnier,
                    partner: this.partner,
                    hilfe: this.hilfe,
                    noPartner: this.noPartner,
                    wishes: this.wishes,
                    token: localStorage.getItem("kendrick"),
                },
                headers: {
                    [Config.getAuthHeaderKey()]: localStorage.getItem("kendrick"),
                }
            }).then((response) => {
                if(response.headers.authtoken) {
                    this.message = "speichern erfolgreich";
                   localStorage.setItem("kendrick", response.headers.authtoken);
                }
                console.log(response);
            });
        }
    }
})

</script>
