<template>
    <div class="container">
            <div class="row justify-content-center">
               <div class="col-md">
                <div class="card bg-dark p-2" style="font-size: 18px; color: #aaa">
                    <p class="h4 card-header border-light">{{$t('AdminDashboard.headings.beerPongHouseRules')}}</p>
                    <p class="card-body text-left" v-html="$t('Content.Tournament.rules')"></p>
                </div>
                </div>
            </div>
        </div>
</template>

<script>
import AuthService from "@/services/AuthService.ts"


export default ({
    name: 'TournamentMain',
    components: {

    },
    async mounted() {
        AuthService.authorize();
    }
})
</script>

<style scoped>

</style>