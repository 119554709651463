<template>
    <div class="container">
        <div class="row justify-content-center">

            <div class="col-md">

                <div class="card bg-dark p-2 mb-2" style="font-size: 18px; color: #aaa">
                    <p class="h4 card-header border-light">{{ $t('Content.FotoUpload.label') }}</p>

                    <div class="card-body">
                        <div class="mb-5 col">
                            <div v-if="successMessage" class="alert alert-success d-block offset-md-3 col-md-6">{{ successMessage }}</div>
                            <div v-if="errorMessage" class="alert alert-danger d-block offset-md-3 col-md-6">{{ errorMessage }}</div>
                            <div v-if="uploading" class="alert alert-info d-block offset-md-3 col-md-6">uploading...</div>

                            <label v-if="!uploading" class="btn btn-info d-block col-md-6 offset-md-3">
                                <input type="file" @change="prepareFile" ref="file" multiple>
                                {{ $t('Content.FotoUpload.selectFotos') }}
                            </label>
                            <small>max 50 at a time</small>
                            <div v-if="!uploading" class="col-md-6 offset-md-3">
                                <button :disabled="imageUrls.length < 1" @click="upload" class="btn btn-success d-block" style="width: 100%;">{{ $t('Content.FotoUpload.uploadNow') }}</button>
                            </div>
                        </div>

                        <div v-if="imageUrls.length > 0" class="card bg-secondary">
                            <p class="h4 card-header border-light">{{ $t('Content.FotoPreview.label') }}</p>
                            <div class="row">
                                <div v-for="imageUrl in imageUrls" class="col-6 col-md-3 col-lg-2 mb-2">
                                    <img :src="imageUrl" style="max-width: 100%" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card bg-dark p-2" style="font-size: 18px; color: #aaa">
                    <p class="h4 card-header border-light mb-2">{{ $t('Content.FotoUpload.yourUploads') }}</p>

                    <div clas="card-body">
                        <FotoUploadUserOverview :key="uploads"></FotoUploadUserOverview>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>



<script>
import AuthService from "@/services/AuthService.ts"
import axios from "axios";
import Config from '@/services/ConfigServerice.ts';
import FotoUploadUserOverview from "./FotoUploadUserOverview.vue";

export default ({
    name: 'FotoUpload',
    components: {
        FotoUploadUserOverview,
    },
    data() {
        return {
            images: [],
            imageUrls: [],
            successMessage: '',
            errorMessage: '',
            uploading: false,
            uploads: 0,
        }
    },
    async mounted() {
        AuthService.authorize();
    },
    methods: {
        prepareFile() {

            this.successMessage = "";
            this.errorMessage = "";
            this.uploading = false;
            this.images = this.$refs.file.files;
            this.imageUrls = Array.from(this.images).map((x => URL.createObjectURL(x)));
        },
        async upload() {

            this.uploading = true;

            const formData = new FormData();
            for (var i = 0; i < this.images.length; i++) {
                console.log(this.images[i]);
                const name = 'file-' + (i+1);
                formData.append(name, this.images[i]);
            }

            //@TODO whats this for??
            var params = new URLSearchParams();
            params.append('data', formData);

            try {

            const response = await axios.post(Config.getApiBaseUrl(), formData, {headers: {
                        'Content-Type' : 'multipart/form-data',
                        [Config.getAuthHeaderKey()]: localStorage.getItem("kendrick"),
                        Method: 'foto_upload',
            }});
            if(response.status == 200) {
                this.successMessage = "erfolgreich hochgeladen";
                this.errorMessage = "";
                this.imageUrls = [];
                this.images = [];
            }
            } catch(e) {
                this.successMessage = "";
                this.errorMessage = "fehler";
            }

            this.uploading = false;
            this.uploads++;
        }
    }
})
</script>

<style scoped>

input[type="file"] {
    display: none;
}

</style>