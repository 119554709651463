import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import LoginPanel from './components/LoginPanel'
import SignUpPanel from './components/SignUpPanel'
//import MainFrame from './components/authorized/MainFrame'
import InvitationMain from './components/authorized/InvitationMain'
import TournamentMain from './components/authorized/TournamentMain'
import SleepingMain from './components/authorized/SleepingMain'
import AddressMain from './components/authorized/AddressMain'
import AdminDashboard from './components/authorized/admin/AdminDashboard'
import FotoUpload from './components/authorized/FotoUpload'
import VueI18n from 'vue-i18n'
import texts from './i18n_texts.ts'

Vue.config.productionTip = false

Vue.use(VueRouter);
Vue.use(VueI18n);

function isAuthenticated() {
  //check local storage for jwt
  if(localStorage.getItem("kendrick")) {
    return true;
  }
  return false;
}

const routes = [
  { path: '/',
    component: InvitationMain,
    beforeEnter(to, from, next) { isAuthenticated() ? next() : next('/login'); }
  },
  { path: '/invitation',
    component: InvitationMain,
    beforeEnter(to, from, next) { isAuthenticated() ? next() : next('/login'); }
  }, 
  { path: '/address',
    component: AddressMain,
    beforeEnter(to, from, next) { isAuthenticated() ? next() : next('/login'); }
  },
  { path: '/sleeping',
    component: SleepingMain,
    beforeEnter(to, from, next) { isAuthenticated() ? next() : next('/login'); }
  },
  { path: '/tournament',
    component: TournamentMain,
    beforeEnter(to, from, next) { isAuthenticated() ? next() : next('/login'); }
  },
  { path: '/fotoUpload',
    component: FotoUpload,
    beforeEnter(to, from, next) { isAuthenticated() ? next() : next('/login'); }
  },
  { path: '/admin',
    component: AdminDashboard,
    beforeEnter(to, from, next) { isAuthenticated() ? next() : next('/login'); }
  },
  { path: '/login',
    component: LoginPanel,
    beforeEnter(to, from, next) { isAuthenticated() ? next('invitation') : next(); }
  },
  { path: '/signup',
    component: SignUpPanel,
    beforeEnter(to, from, next) { isAuthenticated() ? next('invitation') : next(); }
  },
  

]

const router = new VueRouter({
  routes,
  mode: 'history',
})

const messages = texts;

/*{
  de: {
    portalBase: {
      username: 'Benutzername'
    },
    AdminDashboard: {
      headings: {
        invite: 'Einladen',
        invited: 'Eingeladen'
      }
    }
  },
  en: {
    portalBase: {
      username: 'Username'
    },
    AdminDashboard: {
      headings: {
        invite: 'Invite',
        invited: 'Invited'
      }
    }
  }
}*/

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'de', // set locale
  messages, // set locale messages
})


new Vue({
  i18n,
  router,
  render: function(createElement) {
    return createElement(App)
  },
}).$mount('#app')
