<template>
  <div class="custom-fullscreen">
    <div class="custom-bg">
      
      <div style="height: 100dvh; overflow: scroll; position:absolute; bottom: 0;">

        <div style="height: 80vh; width:100vw"></div>

            <div class="card bg-dark border-light p-3 custom-card" style="opacity: 0.7;" id="signup-card">
 
              <div class="card-header">
                <h2>{{ $t('portalBase.signIn') }}</h2>
                <div class="pull-right">
                  <button @click="$root.$i18n.locale = 'en'" class="btn"><country-flag country='gb' /></button>
                  <button @click="$root.$i18n.locale = 'de'" class="btn"><country-flag country='de' /></button>
                </div>
              </div>
              <form @submit="signUpBtn">
                <div class="form-group text-left">
                  <input type="password" class="form-control" v-model="password" :placeholder="$t('portalBase.choosePassword')" />
                </div>
                <div class="form-group text-left">
                  <input type="password" class="form-control" v-model="password2" :placeholder="$t('portalBase.confirmPassword')" />
                </div>
                <div class="form-group">
                  <input type="submit" class="btn btn-primary btn-block" :value="$t('portalBase.signIn')" :disabled="password != password2 || password.length < 4">
                </div>
              </form>
            </div>

            <div style="height: 50vh; width: 100vw;"></div>

          </div>
        </div>
        </div>
</template>

<script>

import Config from '@/services/ConfigServerice.ts';
import CountryFlag from 'vue-country-flag';
const axios = require('axios').default;



export default {
  name: 'SignUpPanel',
  components: {
    CountryFlag
  },
  props: {
    
  },
  data() {
    return {
      password: "",
      password2: "",
      token: this.$route.query.token,
      lets_go: 0,
      deviceOrientation: 'portrait',
    }
  },
   mounted() {
      console.log(this.$route.query);
      //console.log(this.$route.params);
      //has valid token ? load welco,e : ;
      window.addEventListener(
        "orientationchange",
        this.handleOrientationChange
      );
    
      if (window.screen.orientation.type === 'landscape-primary') {
        this.deviceOrientation = "landscape";
      }
      else {
        this.deviceOrientation = "portrait";
      }
    },
  methods: {  
    handleOrientationChange() {
      const orientation = window.screen.orientation.type
      if (orientation === "portrait-primary") {
        this.deviceOrientation = "portrait";
      } else if (orientation === "landscape-primary") {
        this.deviceOrientation = "landscape";
      }
    },
    handleVidEnd() {
      let letsGo = document.getElementById('signup-card');
      letsGo.scrollIntoView({ behavior: 'smooth'});
    },
    signUpBtn(e) {
      e.preventDefault();
      console.log(this.$route.query.token);
      axios
      .get(Config.getApiBaseUrl() + 'index.php', {
        params: {
          method: 'sign_up',
          password: this.password,
          token: this.$route.query.token,
        }
      })
      .then(response => {
        if(response.headers.authtoken) {
          console.log(response.headers.authtoken);
          localStorage.setItem("kendrick", response.headers.authtoken);
          window.location.reload();
        }
      });
    }
  }
}
</script>


<style scoped>

.custom-fullscreen {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  overflow: hidden;
}
  
.custom-bg {
  height: 100%;
  background-size:contain;
  background-repeat: no-repeat;
  background-position: top center;
}
.custom-card {
  opacity: 0.85;
  color: white;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: -15vh;
  border: none;
  border-radius: 25px;
}

@media(orientation: landscape) {
  .custom-card {
    max-width: 45%;
  }
  .custom-bg {
    background-image: url('../../img/TitelbildQuerformat.png');
  }
}

@media(orientation: portrait) {
  .custom-bg {
    background-image: url('../../img/TitelbildHochformat.png');
  }
}


</style>