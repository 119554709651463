<template>
  <div class="container p-0">
  <div id="app" class="main-styles-kendrick"><!-- :style="{backgroundImage:'url(https://vuejs.org/images/logo.png)'}">-->
    <NavigationBar></NavigationBar>
    <router-view></router-view>
  </div>
  </div>
</template>

<script>
import NavigationBar from './components/authorized/NavigationBar.vue';

export default {
  name: 'App',
  components: {
    NavigationBar
  },
  data() {
    return {
    }
  },
  methods: {
  },

}


</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  background-color: #555;
  padding-bottom: 5px;
}
</style>
